import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import axios from "axios"

import { StyledRealms } from "./Realms.styled"

const Realms = ({ children }) => {
  const [pins, setPins] = useState([])

  useEffect(() => {
    axios.get(`https://www.pinterest.com/lostwun/sirothel/`).then(response => {
      console.log(response)
    })
  }, [])
  return <StyledRealms>{children}</StyledRealms>
}

Realms.propTypes = {
  children: PropTypes.node,
}

export default Realms
